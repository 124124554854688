import React from 'react';
import Transition from '@components/Transition';
import Title from '@components/Title';
import Blurb from '@components/Blurb';
import BlurbImage from '@components/BlurbImage';
import Text from '@components/Text';
import Browser from '@components/Browser';
import rollerbadeHomepageImg from '@assets/img/rollerblade-1.jpg';
import rollerbladeLaptopGif from '@assets/img/rollerblade-computer.gif';
import rollerbladePersonGif from '@assets/img/rollerblade-shirt.gif';
import { Helmet } from 'react-helmet';
import Link from '@components/Link';

const Gatku = () => (
    <Transition>
        <Helmet>
            <title>Rollerblade.js | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/rollerblade" />
        </Helmet>
        <Title>Rollerblade.js</Title>
        <Blurb
            left={() => (
                <Text>
                    <ul>
                        <li>Open Source Software</li>
                        <li>2014</li>
                    </ul>
                </Text>
            )}
            right={() => (
                <>
                    <Text><Link href="https://github.com/austenpayan/rollerblade" newTab>Rollerblade.js</Link> was a personal project I released in the summer of 2014 that lets developers easily add 360° image rotators to their webpages. While working on the Gatku Project, I came across the need for a rotator, but the plugins available just didn't suit my needs. I built my own, and eventually released it under the moniker "Rollerblade.js".</Text>
                    <br />
                    <Text>It went viral among the jQuery community and was featured on many plugin aggregation websites. Over the last few years, rollerblade.js has collected over 500 stars on Github.</Text>
                </>
            )}
        />
        <Browser
            src={rollerbadeHomepageImg}
        />
        <Blurb
            left={() => (
                <Text>How it works.</Text>
            )}
            right={() => (
                <>
                    <Text>Clicking and dragging on the image will cycle through an array of images, with the current visible index derived from the distance between the beginning of the drag event to the current cursor location. This gives the impression of spinning a product on a turntable. Images are preloaded to prevent janky "frame drops", ensuring a smooth experience for the user.</Text>
                    <BlurbImage src={rollerbladeLaptopGif} />
                    <Text>Touch events are also supported, allowing mobile users to interact with the rotator using their fingers.</Text>
                    <BlurbImage src={rollerbladePersonGif} />
                </>
            )}
            useTopRule={true}
        />
        <Blurb
            left={() => (
                <Text>Conclusion</Text>
            )}
            right={() => (
                <>
                    <Text>Rollerblade.js was a successful foray into open source, and I learned how to have productive dialog with developers that were using the plugin in their own projects, considering their experiences and implementing their ideas.</Text>
                    <br />
                    <Text>I spent a good amount of time coming up with branding for the project, which I believe added to its success. A consistent color scheme and good font pairings go a long way, even for a simple jQuery plugin.</Text>
                </>
            )}
            useTopRule={true}
        />
    </Transition>
);

export default Gatku;